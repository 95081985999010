<template>
  <div class="row mx-0 mb-1">
    <div class="col-12 p-0">
      <div :for="id" class="form-label medium" v-if="label" v-html="label" :class="[color, size]"></div>
      <div class="row m-0 align-items-center w-100 position-relative">
        <div class="col-auto pt-1 currency medium" :class="[color, size]">
          {{ (currencySymbol ? currencySymbol : defaultCurrency) }}
        </div>
        <div class="col p-0" >
          <input
            class="form-control medium"
            :class="[color, size, { error: hasErrors > 0}]"
            type="number"
            min="0.00"
            step="0.01"
            :id="id"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            v-model="value"
          >
        </div>
      </div>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyField',
  // props: {
  //   id: {
  //     type: String,
  //     default: Math.random().toString(20).substr(2, 6)
  //   },
  //   placeholder: String,
  //   label: String,
  //   modelValue,
  //   size: String,
  //   color: String,
  //   errors: Array,
  //   hasErrors: Number,
  //   autocomplete: String,
  //   currencySymbol: String
  // },
  props: ['id', 'placeholder', 'label', 'modelValue', 'size', 'color', 'errors', 'hasErrors', 'autocomplete', 'currencySymbol'],
  emits: ['update:modelValue'],
  data () {
    return {
      isFocus: false,
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      } else {
      }
    }
  },
  computed: {
    // showCurrency () {
    //   return this.isFocus || this.modelValue !== ''
    // },
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 0.5rem 0 1.75rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.goldgold {
    background-color: var(--gold-color);
    color: #000;
    border: 2px solid var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.transparent {
    border-color: transparent;
    color: var(--green-color);
    background-color: transparent;
  }
  .form-control.outline-green {
    border-color: var(--green-color);
    color: var(--normal-color);
    background-color: transparent;
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);;
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.goldgold:focus {
    background-color: var(--gold-color);
    color: #000;
    border: 2px solid var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.transparent:focus {
    color: var(--green-color);
    background-color: #f4f4f4;
    border-color: #f4f4f4;
  }
  .form-control.outline-green:focus {
    border-color: var(--green-color);
    color: var(--normal-color);
    background-color: transparent;
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  .form-control.big.transparent:focus {
    color: var(--green-color);
  }
  .form-label.small {
    line-height: 1;
    font-size: 0.6rem;
    margin-bottom: 0.3rem;
  }
  .form-label.outline-green {
    color: var(--green-color);
  }
  .currency {
    position: absolute;
    color: var(--green-color-light);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    border-top: 2px solid transparent;
    line-height: 2;
  }
  .currency.big {
    left: 6%;
  }
  .currency.small {
    font-size: 0.7rem;
    height: calc(1.5rem + 4px);
    font-weight: 500;
    line-height: 1.5;
  }
  .currency.gold {
    color: var(--gold-color);
  }
  .currency.goldgold {
    color: #000;
  }
  .currency.white {
    color: #fff;
  }
  .currency.transparent {
    color: var(--green-color);
  }
  .currency.outline-green {
    color: var(--normal-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .goldgold::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000 !important;
    opacity: 1; /* Firefox */
  }

  .goldgold:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
  }

  .goldgold::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .transparent::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color) !important;
    opacity: 1; /* Firefox */
  }
  .transparent:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color) !important;
  }
  .transparent::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
  .big {
    height: 65px;
    border-radius: 57px;
    font-size: 36px;
    line-height: 1.5;
    /* text-align: center; */
    color: var(--gold-color);
    font-weight: 100;
    font-family: "quicksand_light";
    /* padding: 7% 19% 7% 19%; */
  }
  .bigMedium {
    height: 51px;
    border-radius: 57px;
    font-size: 27px;
    line-height: 1.5;
    /* text-align: center; */
    color: var(--gold-color);
    font-weight: 100;
    font-family: "quicksand_light";
    /* padding: 7% 19% 7% 19%; */
  }
  .form-control.big {
    padding:7% 19% 7% 30%;
  }
  .form-control.small {
    font-size: 0.7rem;
    height: calc(1.5rem + 4px);
    padding: 0 0.25rem 0 1.5rem;
    font-weight: 500;
    text-align: left;
  }
</style>
